import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import SpanText from '~components/SpanText'
import { Darr } from '~components/Svg'
import Image from '~components/Image'
import { useState } from 'react'
import { useEffect } from 'react'
import SanityLink from '~components/SanityLink'

const HeaderSlider = ({ className, slides, onScrollDown }) => {

	const [integer, setInteger] = useState(0)
	const [initial, setInitial] = useState(undefined)

	useEffect(() => {
		setInitial(window.innerHeight)
	}, [])	

	useEffect(() => {
		const interval = setInterval(() => {
			if (integer >= (slides?.length - 1)) {
				setInteger(0)
			} else {
				setInteger(integer => integer + 1)
			}
		}, 5000)
		return () => clearInterval(interval)
	}, [integer])

	return (
		<Wrap 
			className={className} 
			initial={initial}
		>
			{slides.map((slide, i) => (
				<Slide key={'slide' + i} show={integer === i}	>
					<HeaderImageDesk 
						image={slide.imageDesktop} 
						hide={slide.imageMobile} 
						cover
						initial={initial}
					/>
					<HeaderImageMob 
						image={slide.imageMobile} 
						cover 
						initial={initial}
					/>
					<HeaderOverlay>
						<div>
							<Headline>
								<SpanText content={slide?.headline}/>
							</Headline>
							{slide?.triggerSlideDown ?
								<LearnMore 
									onClick={() => onScrollDown()}
									className='h6'
								>
									{slide.buttonText}
									<DownArrow />
								</LearnMore>
								:
								<SlideButton 
									onClick={() => onScrollDown()}
									className='h6'
									link={slide?.link}
								>
									{slide.buttonText}
								</SlideButton>
							}

						</div>
					</HeaderOverlay>
					{!slide?.triggerSlideDown && <SlideLink link={slide?.link}/> }
				</Slide>
			))}
		</Wrap>
	)
}

const Wrap = styled.div`
	height: 100vh;
	position: relative;
	${mobile}{
		height: ${props => `${props.initial}px`};
	}
`
const Slide = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	opacity: ${ props => props.show ? '1' : '0'};
	transition: opacity 0.8s;
	pointer-events: ${ props => props.show ? 'all' : 'none'};
`
const HeaderImage = styled(Image)`
	object-fit: cover;
	height: 100%;
`
const HeaderImageDesk = styled(HeaderImage)`
	${mobile}{
		display: ${ props => props.hide ? 'none' : 'black'};
		height: ${props => `${props.initial}px`};
	}
`
const HeaderImageMob = styled(HeaderImage)`
	display: none;
	${mobile}{
		display: block;
		height: ${props => `${props.initial}px`};
	}
`
const HeaderOverlay = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
`
const Headline = styled.h1`
	text-align: center;
	margin-top: calc(var(--xxxl) + 40px);
	margin-bottom: var(--xxxl);
	${mobile}{
		font-size: 9vw;
	}
`
const LearnMore = styled.button`
	margin: 0 auto;
	display: block;
	position: relative;
	:after{
		content: '';
		height: 1px;
		width: 100%;
		background-color: var(--white);
		position: absolute;
		bottom: -4px;
		left: 0;
	}
`
const DownArrow = styled(Darr)`
	width: 8px;
	margin-left: 9px;
`
const SlideButton = styled(SanityLink)`
	margin: 0 auto;
	display: inline-block;
	position: relative;
	:after{
		content: '';
		height: 1px;
		width: 100%;
		background-color: var(--white);
		position: absolute;
		bottom: -4px;
		left: 0;
	}
`
const SlideLink = styled(SanityLink)`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 2;
`

HeaderSlider.propTypes = {
	className: PropTypes.string,
	slides: PropTypes.array,
	onScrollDown: PropTypes.func,
}

export default HeaderSlider