import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import '~styles/flickity.css'
import canUseDOM from '~utils/canUseDOM'
import { Rarr } from '~components/Svg'
import RichText from '~components/RichText'
import Image from '~components/Image'

const Slider = ({ className, slides, rounded }) => {

	const [init, setInit] = useState()
	const [integer, setInteger] = useState(0)

	const flkty = useRef()
	const flickityEl = useRef()

	useEffect(() => {
		if (canUseDOM && init){
			const Flickity = require('flickity')
			flkty.current = new Flickity(flickityEl.current, flickityOptions)
		}
		setInit(true)
	}, [init])

	useEffect(() => {
		if (init) {
			flkty?.current?.on('change', () => {
				setInteger(flkty?.current?.selectedIndex)
			} )
		}
	}, [init])

	const flickityOptions = {
		prevNextButtons: false,
		pageDots: false,
		adaptiveHeight: false,
		wrapAround: true,
		cellAlign: 'left',
		freeScroll: false,
		contain: true,
		fade: true
	}

	const next = () => {
		flkty?.current?.next()
	}
	const prev = () => {
		flkty?.current?.previous()
	}

	return (
		<Wrap className={className}>
			<Flickity ref={flickityEl}>
				{slides?.map(s => (
					<Slide rounded={rounded} key={s?.asset?._id} image={s} aspectRatio={1.56}/>
				))}
			</Flickity>	
			<Buttons>
				<Left onClick={() => prev()}><LeftArrow /></Left>
				<Title>
					<RichText content={slides && slides[integer]?.caption} /> 
				</Title>
				<Right onClick={() => next()}><RightArrow /></Right>
			</Buttons>
		</Wrap>
	)
}

const Wrap = styled.div`
	
`
const Flickity = styled.div`
	margin-bottom: 35px;
	${tablet}{
		margin-bottom: 28px;
	}
`
const Slide = styled(Image)`
	width: 100%;
	margin-right: var(--s);
	border-radius: ${props => props.rounded ? '10px' : '0px'};
	overflow: hidden;
`
const Buttons = styled.div`
	display: flex;
	align-items: center;
	height: 53px;
	border-top: 1px solid;
	border-bottom: 1px solid;
`
const Left = styled.button`
	display: flex;
	align-items: center;
	margin-left: 14px;
`
const Right = styled.button`
	display: flex;
	align-items: center;
	margin-right: 14px;
`
const Title = styled.h6`
	flex: 1;
	text-align: center;
`
const LeftArrow = styled(Rarr)`
	width: 10px;
	transform: rotate(180deg);
`
const RightArrow = styled(Rarr)`
	width: 10px;
`

Slider.propTypes = {
	className: PropTypes.string,
	slides: PropTypes.array,
}

export default Slider