import React from 'react'
import PropTypes from 'prop-types'
import resolveLink from '~utils/resolveLink'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import SanityLink from '~components/SanityLink'
import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

const MovingSpan = ({ children, className, index }) => {

	const transition = {
		type: 'spring',
		damping: 14,
		stiffness: 50,
		delay: 0.3 + (index * 0.02) + (Math.pow(index, 5) * 0.0000001)
	}

	return (
		<motion.span
			className={className}
			animate={{
				y: 0,
				opacity: 1
			}}
			initial={{
				// y: 100,
				y: 0,
				opacity: 0
			}}
			transition={transition} 
		>
			{children}
		</motion.span>
	)
}

const serializers = {
	marks: {
		link: props => <SanityLink link={props.mark} css={css`text-decoration: underline;`}>{props.children}</SanityLink>
	},
	types: {
		block: props => {
			return <>
				{props.children.map(child => (
					typeof child === 'string' ?
						<OuterSpan key={child}>{child.split('').map((letter, i) => 
							letter === ' ' ? <span>{letter}</span> :
								<InnerSpan key={letter} index={i}>{letter}</InnerSpan>
						)}</OuterSpan>
						: child
				))}
			</>
			return BlockContent.defaultSerializers.types.block(props)
		},
	}
}

const SpanText = ({ content }) => 
	<Wrap>
		<BlockContent blocks={content} serializers={serializers} ignoreUnknownTypes={true}/>
	</Wrap>

const Wrap = styled.div`
	
`

const OuterSpan = styled.span`
	/* overflow: hidden; */
	display: inline-block;
	position: relative;
	line-height: 1;
`

const InnerSpan = styled(MovingSpan)`
	position: relative;
	display: inline-block;
	line-height: 1;
	/* transform: translateY(50%); */
`

SpanText.propTypes = {
	content: PropTypes.array,
}

export default SpanText
